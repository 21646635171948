import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { getRouterSelectors } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { keystonePage, termsVersions } from 'src/app/core/interfaces/interfaces';
import { KeystoneService } from 'src/app/core/services/keystone/keystone.service';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';
import { selectHelpers } from 'src/app/core/store/helpers/selector/helpers.selectors';
import { NgIf, NgFor } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, TranslocoModule],
})
export class InformationComponent implements OnInit, OnDestroy {
  @ViewChild('childDiv') childDiv!: ElementRef;
  @Input()
  preselectedSlug: string | null = null;
  @Input()
  isLanding: boolean = false;
  @Input()
  termsRenewConfirmation: boolean = false;
  @Input()
  isTermsModal: boolean = false;
  @Input()
  isLoyalty: boolean = false;
  slug: string = '';
  unsubus$: Subject<boolean> = new Subject();
  keystonePage: keystonePage = {};
  Router$: Observable<any> = this.store.select(getRouterSelectors().selectUrl);
  helper$: Observable<any> = this.store.select(selectHelpers);
  isTerms: boolean = false;
  isMobile: boolean = false;
  isOpenVersions: boolean = false;
  selected: string = '';
  termsVersions: Array<termsVersions> = [];
  newTermsState: boolean = false;
  termsVersionsList: any[] = [];
  previousScrollPosition: number = 0;
  constructor(
    private router: Router,
    private navigator: NavigationService,
    private keystone: KeystoneService,
    private store: Store
  ) {
    if (this.router.url == '/info') {
      this.navigator.navigateTo('/');
    }
    if (this.router.url != '/auth/terms' && this.router.url.indexOf('info/') == -1) {
      this.newTermsState = true;
    }
  }

  ngOnInit(): void {
    if (this.preselectedSlug) {
      this.slug = this.preselectedSlug;
      this.getPage();
    } else {
      this.Router$.pipe(takeUntil(this.unsubus$)).subscribe((res: any) => {
        if (res.includes('info')) {
          this.slug = res.match(/info\/([^/(]+)/)[1] === 'amlpolicy' ? 'amlPolicy' : res.match(/info\/([^/(]+)/)[1];
          if (!this.router.url.includes('aside')) {
            this.getPage();
          }
        }
      });
    }
    this.helper$.pipe(takeUntil(this.unsubus$)).subscribe((res: any) => {
      if (res.isLoaded) {
        this.isMobile = res.isMobile;
      }
    });
  }

  getPage() {
    this.checkIfTerms();
    if (this.isTerms) {
      this.keystone
        .getAllTermsPages()
        .pipe(takeUntil(this.unsubus$))
        .subscribe((res: any) => {
          if (res.length > 0) {
            this.termsVersionsList = res;
            if (!this.router.url.includes('aside')) {
              this.scrollToTop();
            }
            this.selected = res[0].version;
            this.keystonePage = {
              slug: 'terms',
              opengraphKeywords: res[0]?.pdf,
              description: res[0]?.content,
            };
            this.termsVersions = res.map((term: any) => ({
              title: term.version,
              Name: term.visibleName,
            }));
            if (this.isLoyalty) {
              setTimeout(() => {
                const element = this.childDiv?.nativeElement?.querySelector('.LoyaltyTerms');
                element?.scrollIntoView({ behavior: 'smooth' });
              }, 150);
            }
          }
        });
      return;
    }
    this.keystone
      .getPage(this.slug)
      .pipe(takeUntil(this.unsubus$))
      .subscribe((res: any) => {
        this.keystonePage = res;
        if (!this.router.url.includes('aside')) {
          this.scrollToTop();
        }
      });
  }

  scrollToTop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
      this.childDiv.nativeElement.scrollTop = 0;
    }, 100);
  }

  checkIfTerms() {
    this.isTerms = this.slug == 'terms' ? true : false;
  }

  selectTerms(event: termsVersions) {
    this.selected = event.title;
    this.openVersions();
    this.termsVersionsList.find((value: any) => {
      if (value.version === event.title) {
        this.keystonePage = {
          ...this.keystonePage,
          opengraphKeywords: value.pdf,
          description: value.content,
        };
      }
    });
  }

  openVersions() {
    this.isOpenVersions = !this.isOpenVersions;
  }

  ngOnDestroy(): void {
    this.unsubus$.next(true);
    this.unsubus$.complete();
  }
}
